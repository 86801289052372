import React from 'react'

/**
 * Icons {name}
 *
 * To add a new icon, put the SVG file into src/assets/icons
 * and add it to the iconsMap below.
 *
 * Recommended to load any new icons into IcoMoon http://icomoon.io/app/
 * before and then download them from there, so that they get cleaned up and properly formatted.
 */

const iconsMap = {
  'sc-logo': () => require('assets/icons/sc-logo.svg'),
  'button-divider': () => require('assets/icons/divider-vertical.svg'),
  'stripe-lean': () => require('assets/icons/stripe-lean.svg'),
  // 'stripe-icon': () => require('assets/icons/stripe.png'),
  'sc-logo-mono': () => require('assets/icons/sc-logo-mono.svg'),
  'sc-mark': () => require('assets/icons/sc-mark.svg'),
  'am-logo-by-sc': () => require('assets/icons/am-logo-by-sc.svg'),
  'am-logo': () => require('assets/icons/am-logo.svg'),
  'am-mark': () => require('assets/icons/am-mark.svg'),
  'am-mark-mono': () => require('assets/icons/am-mark-mono.svg'),
  adjuster: () => require('assets/icons/adjuster.svg'),
  airplane: () => require('assets/icons/airplane.svg'),
  arrow: () => require('assets/icons/arrow.svg'),
  at: () => require('assets/icons/at.svg'),
  bed: () => require('assets/icons/bed.svg'),
  bed2: () => require('assets/icons/bed2.svg'),
  'better-bed': () => require('assets/icons/better-bed.svg'),
  calendar: () => require('assets/icons/calendar.svg'),
  card: () => require('assets/icons/card.svg'),
  caret: () => require('assets/icons/caret.svg'),
  chat: () => require('assets/icons/chat.svg'),
  checkmark: () => require('assets/icons/checkmark.svg'),
  'checkmark-i': () => require('assets/icons/checkmark-i.svg'),
  'checkmark-solid': () => require('assets/icons/checkmark-solid.svg'),
  close: () => require('assets/icons/close.svg'),
  'collapse-menu': () => require('assets/icons/collapse-menu.svg'),
  contacts: () => require('assets/icons/contacts.svg'),
  ellipsis: () => require('assets/icons/ellipsis.svg'),
  email: () => require('assets/icons/email.svg'),
  explore: () => require('assets/icons/explore.svg'),
  'external-link': () => require('assets/icons/external-link.svg'),
  'eye-blocked': () => require('assets/icons/eye-blocked.svg'),
  'eye-framed': () => require('assets/icons/eye-framed.svg'),
  eye: () => require('assets/icons/eye.svg'),
  'flight-tickets': () => require('assets/icons/flight-tickets.svg'),
  'hamburger-menu': () => require('assets/icons/hamburger-menu.svg'),
  help: () => require('assets/icons/help.svg'),
  info: () => require('assets/icons/info.svg'),
  invite: () => require('assets/icons/invite.svg'),
  logout: () => require('assets/icons/logout.svg'),
  maintenance: () => require('assets/icons/maintenance.svg'),
  map: () => require('assets/icons/map.svg'),
  menu: () => require('assets/icons/menu.svg'),
  message: () => require('assets/icons/message.svg'),
  minus: () => require('assets/icons/minus.svg'),
  'mix-shuffle-circle': () => require('assets/icons/mix_shuffle_circle.svg'),
  'money-bed': () => require('assets/icons/money-bed.svg'),
  money: () => require('assets/icons/money.svg'),
  night: () => require('assets/icons/night.svg'),
  notifications: () => require('assets/icons/notifications.svg'),
  people: () => require('assets/icons/people.svg'),
  person: () => require('assets/icons/person.svg'),
  phone: () => require('assets/icons/phone.svg'),
  'pin-map': () => require('assets/icons/pin-map.svg'),
  pin: () => require('assets/icons/pin.svg'),
  plus: () => require('assets/icons/plus.svg'),
  premium: () => require('assets/icons/premium.svg'),
  prime: () => require('assets/icons/prime.svg'),
  reload: () => require('assets/icons/reload.svg'),
  salary: () => require('assets/icons/salary.svg'),
  search: () => require('assets/icons/search.svg'),
  send: () => require('assets/icons/send.svg'),
  settings: () => require('assets/icons/settings.svg'),
  share: () => require('assets/icons/share.svg'),
  smartphone: () => require('assets/icons/smartphone.svg'),
  smile: () => require('assets/icons/smile.svg'),
  staypals: () => require('assets/icons/staypals.svg'),
  stripe: () => require('assets/icons/stripe.svg'),
  'tap-hold': () => require('assets/icons/tap-hold.svg'),
  uncheckmark: () => require('assets/icons/uncheckmark.svg'),
  'yt-play': () => require('assets/icons/yt-play.svg'),
  'twitter-square': () => require('assets/icons/twitter-square.svg'),
  'facebook-square': () => require('assets/icons/facebook-square.svg'),
  instagram: () => require('assets/icons/instagram.svg'),
  'event-create': () => require('assets/icons/event-create.svg'),
  'event-share': () => require('assets/icons/event-share.svg'),
  google: () => require('assets/icons/google-logo.svg')
}

const Icon = ({ name, className = '', height, width, ...props }) => {
  const requireIcon = iconsMap[name]

  if (!requireIcon) {
    const iconsNames = Object.keys(iconsMap).join(', ')
    throw new Error(
      `${name} not found in icons. Valid icon names are: \n ${iconsNames}`
    )
  }

  const Src = requireIcon()

  return (
    <Src.ReactComponent
      className={`icon ${className || ''}`}
      {...props}
      style={{ width, height }}
    />
  )
}

export default Icon
